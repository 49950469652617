.mapMarker {
  /* background: #002147; */
  border-radius: 10px;
  height: 10px;
  position: relative;
  width: 10px;
}

.leaflet-div-icon {
  background: #f90404 !important;
  border: 1px solid #666 !important;
  height: 12px !important;
  width: 12px !important;
  border-radius: 12px !important;
}

.leaflet-div-icon::before {
  animation: blink 1s infinite ease-out;
  border-radius: 60px;

  box-shadow: inset 0 0 0 1px #f12106;
  content: "";
  height: 10px;
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
}

@keyframes blink {
  100% {
    height: 50px;
    opacity: 1;
    width: 50px;
  }
}
